import { Icon, IconProps } from '@chakra-ui/react';

export const InterestedLooper = ({
	width = '590px',
	height = '288px',
	...props
}: IconProps) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 590 288"
			fill="none"
			{...props}
		>
			<path
				opacity="0.010101"
				d="M274.915 363.449C269.047 357.372 267.759 348.208 271.725 340.749L409.301 82.0055C413.267 74.5464 421.585 70.4895 429.904 71.9565L718.497 122.843C726.817 124.31 733.245 130.967 734.421 139.333L775.205 429.526C776.381 437.891 772.036 446.063 764.443 449.766L501.056 578.228C493.463 581.932 484.349 580.325 478.481 574.248L274.915 363.449Z"
				stroke="url(#paint0_linear_2617_20921)"
			/>
			<path
				opacity="0.020202"
				d="M271.609 359.827C265.848 353.649 264.72 344.463 268.816 337.074L410.602 81.286C414.697 73.8973 423.085 69.9862 431.378 71.5981L718.461 127.401C726.754 129.013 733.065 135.782 734.095 144.167L769.736 434.444C770.766 442.829 766.279 450.923 758.623 454.493L493.567 578.091C485.911 581.661 476.826 579.895 471.065 573.717L271.609 359.827Z"
				stroke="url(#paint1_linear_2617_20921)"
			/>
			<path
				opacity="0.030303"
				d="M268.385 356.21C262.732 349.932 261.764 340.728 265.988 333.412L411.923 80.6459C416.147 73.3298 424.601 69.5656 432.865 71.322L718.356 132.005C726.619 133.761 732.811 140.639 733.695 149.041L764.203 439.311C765.086 447.712 760.459 455.727 752.741 459.163L486.106 577.877C478.388 581.313 469.336 579.389 463.683 573.111L268.385 356.21Z"
				stroke="url(#paint2_linear_2617_20921)"
			/>
			<path
				opacity="0.040404"
				d="M265.243 352.599C259.7 346.223 258.894 337.004 263.245 329.762L413.266 80.0857C417.617 72.8444 426.135 69.2283 434.367 71.1287L718.183 136.653C726.414 138.553 732.486 145.538 733.222 153.953L758.609 444.126C759.345 452.542 754.579 460.475 746.802 463.776L478.676 577.588C470.9 580.889 461.883 578.807 456.34 572.432L265.243 352.599Z"
				stroke="url(#paint3_linear_2617_20921)"
			/>
			<path
				opacity="0.050505"
				d="M262.181 348.994C256.751 342.523 256.105 333.291 260.582 326.127L414.626 79.6046C419.103 72.4403 427.684 68.9735 435.881 71.0173L717.939 141.342C726.136 143.386 732.085 150.476 732.674 158.903L752.952 448.888C753.541 457.316 748.637 465.164 740.804 468.329L471.278 577.224C463.445 580.389 454.466 578.15 449.035 571.679L262.181 348.994Z"
				stroke="url(#paint4_linear_2617_20921)"
			/>
			<path
				opacity="0.0606061"
				d="M259.2 345.397C253.883 338.832 253.399 329.59 258 322.505L416.003 79.2021C420.604 72.117 429.244 68.8005 437.404 70.987L717.625 146.072C725.785 148.258 731.609 155.451 732.051 163.887L747.234 453.595C747.676 462.031 742.636 469.793 734.749 472.82L463.912 576.785C456.025 579.812 447.086 577.417 441.769 570.852L259.2 345.397Z"
				stroke="url(#paint5_linear_2617_20921)"
			/>
			<path
				opacity="0.0707071"
				d="M256.3 341.81C251.099 335.153 250.776 325.904 255.5 318.9L417.397 78.8792C422.121 71.8756 430.817 68.7104 438.938 71.0389L717.24 150.841C725.361 153.169 731.059 160.462 731.353 168.905L741.457 458.247C741.752 466.689 736.577 474.362 728.639 477.251L456.581 576.272C448.642 579.161 439.746 576.61 434.545 569.953L256.3 341.81Z"
				stroke="url(#paint6_linear_2617_20921)"
			/>
			<path
				opacity="0.0808081"
				d="M253.48 338.235C248.396 331.488 248.234 322.235 253.08 315.315L418.803 78.6374C423.649 71.7173 432.399 68.7044 440.478 71.1743L716.783 155.649C724.862 158.119 730.432 165.51 730.579 173.957L735.622 462.843C735.769 471.29 730.461 478.871 722.473 481.621L449.284 575.687C441.297 578.438 432.447 575.732 427.362 568.985L253.48 338.235Z"
				stroke="url(#paint7_linear_2617_20921)"
			/>
			<path
				opacity="0.0909091"
				d="M250.74 334.672C245.775 327.838 245.775 318.583 250.74 311.749L420.224 78.4747C425.189 71.6403 433.991 68.7805 442.025 71.391L716.255 160.494C724.29 163.104 729.729 170.591 729.729 179.039V467.382C729.729 475.83 724.29 483.317 716.255 485.927L442.025 575.03C433.991 577.64 425.189 574.781 420.224 567.946L250.74 334.672Z"
				stroke="url(#paint8_linear_2617_20921)"
			/>
			<path
				opacity="0.10101"
				d="M248.08 331.123C243.235 324.202 243.396 314.949 248.48 308.202L421.655 78.3914C426.74 71.6447 435.59 68.9389 443.577 71.6893L715.655 165.373C723.642 168.123 728.951 175.704 728.803 184.151L723.781 471.862C723.634 480.308 718.064 487.699 709.985 490.169L434.804 574.3C426.726 576.77 417.975 573.757 413.13 566.837L248.08 331.123Z"
				stroke="url(#paint9_linear_2617_20921)"
			/>
			<path
				opacity="0.111111"
				d="M245.498 327.59C240.774 320.586 241.097 311.337 246.298 304.68L423.096 78.3894C428.297 71.7323 437.193 69.1815 445.131 72.0708L714.98 170.288C722.918 173.177 728.094 180.849 727.799 189.292L717.777 476.284C717.482 484.727 711.784 492.02 703.664 494.348L427.621 573.502C419.5 575.831 410.804 572.665 406.08 565.662L245.498 327.59Z"
				stroke="url(#paint10_linear_2617_20921)"
			/>
			<path
				opacity="0.121212"
				d="M242.996 324.072C238.395 316.987 238.879 307.745 244.196 301.18L424.546 78.466C429.862 71.9007 438.801 69.5054 446.688 72.5329L714.233 175.234C722.12 178.261 727.16 186.023 726.718 194.459L711.719 480.645C711.277 489.081 705.453 496.274 697.293 498.46L420.479 572.632C412.319 574.819 403.679 571.502 399.078 564.417L242.996 324.072Z"
				stroke="url(#paint11_linear_2617_20921)"
			/>
			<path
				opacity="0.131313"
				d="M240.572 320.574C236.095 313.409 236.741 304.177 242.171 297.706L426.003 78.6237C431.433 72.1523 440.412 69.9134 448.245 73.078L713.412 180.212C721.244 183.377 726.149 191.225 725.559 199.653L705.61 484.947C705.02 493.374 699.072 500.464 690.875 502.508L413.379 571.695C405.182 573.739 396.601 570.272 392.124 563.108L240.572 320.574Z"
				stroke="url(#paint12_linear_2617_20921)"
			/>
			<path
				opacity="0.141414"
				d="M238.225 317.094C233.874 309.853 234.68 300.634 240.223 294.258L427.464 78.8611C433.006 72.4854 442.024 70.4036 449.8 73.7044L712.515 185.22C720.292 188.521 725.058 196.454 724.322 204.87L699.447 489.187C698.711 497.603 692.64 504.587 684.408 506.488L406.32 570.69C398.088 572.59 389.569 568.974 385.218 561.733L238.225 317.094Z"
				stroke="url(#paint13_linear_2617_20921)"
			/>
			<path
				opacity="0.151515"
				d="M235.956 313.635C231.732 306.319 232.7 297.115 238.352 290.837L428.931 79.1778C434.584 72.8998 443.636 70.9757 451.354 74.4117L711.546 190.257C719.263 193.693 723.891 201.707 723.008 210.109L693.236 493.364C692.353 501.766 686.161 508.644 677.897 510.4L399.306 569.617C391.042 571.373 382.588 567.609 378.364 560.293L235.956 313.635Z"
				stroke="url(#paint14_linear_2617_20921)"
			/>
			<path
				opacity="0.161616"
				d="M233.763 310.199C229.667 302.81 230.795 293.624 236.557 287.446L430.4 79.5748C436.161 73.3964 445.246 71.6306 452.902 75.2008L710.5 195.321C718.156 198.891 722.643 206.985 721.613 215.37L686.975 497.479C685.945 505.864 679.634 512.633 671.341 514.245L392.335 568.478C384.042 570.09 375.655 566.179 371.559 558.79L233.763 310.199Z"
				stroke="url(#paint15_linear_2617_20921)"
			/>
			<path
				opacity="0.171717"
				d="M231.647 306.786C227.681 299.327 228.969 290.162 234.837 284.085L431.87 80.0519C437.738 73.9749 446.852 72.3679 454.445 76.0712L709.379 200.411C716.972 204.114 721.317 212.285 720.141 220.651L680.666 501.531C679.49 509.897 673.062 516.554 664.742 518.021L385.411 567.274C377.092 568.741 368.774 564.684 364.808 557.225L231.647 306.786Z"
				stroke="url(#paint16_linear_2617_20921)"
			/>
			<path
				opacity="0.181818"
				d="M229.606 303.397C225.771 295.87 227.218 286.73 233.192 280.756L433.34 80.6079C439.314 74.6344 448.454 73.1867 455.982 77.0219L708.183 205.525C715.71 209.36 719.912 217.606 718.59 225.95L674.311 505.517C672.989 513.861 666.445 520.405 658.102 521.727L378.534 566.006C370.19 567.328 361.944 563.126 358.109 555.599L229.606 303.397Z"
				stroke="url(#paint17_linear_2617_20921)"
			/>
			<path
				opacity="0.191919"
				d="M227.64 300.035C223.936 292.442 225.544 283.329 231.62 277.46L434.808 81.2438C440.885 75.3754 450.05 74.0874 457.509 78.0534L706.91 210.662C714.369 214.629 718.426 222.947 716.959 231.266L667.91 509.439C666.443 517.759 659.786 524.188 651.42 525.363L371.704 564.675C363.339 565.851 355.167 561.506 351.464 553.913L227.64 300.035Z"
				stroke="url(#paint18_linear_2617_20921)"
			/>
			<path
				opacity="0.20202"
				d="M225.749 296.699C222.178 289.043 223.944 279.958 230.123 274.197L436.274 81.9572C442.453 76.1957 451.638 75.0679 459.027 79.1635L705.562 215.82C712.951 219.916 716.862 228.303 715.25 236.596L661.465 513.294C659.853 521.586 653.085 527.898 644.7 528.928L364.924 563.28C356.539 564.309 348.445 559.823 344.875 552.166L225.749 296.699Z"
				stroke="url(#paint19_linear_2617_20921)"
			/>
			<path
				opacity="0.212121"
				d="M223.93 293.393C220.494 285.676 222.419 276.624 228.697 270.971L437.735 82.7518C444.013 77.099 453.217 76.1317 460.533 80.3556L704.136 221C711.452 225.224 715.217 233.679 713.46 241.942L654.977 517.084C653.221 525.347 646.343 531.54 637.941 532.423L358.193 561.825C349.792 562.709 341.777 558.081 338.341 550.364L223.93 293.393Z"
				stroke="url(#paint20_linear_2617_20921)"
			/>
			<path
				opacity="0.222222"
				d="M222.186 290.116C218.885 282.34 220.967 273.322 227.343 267.78L439.19 83.6234C445.566 78.0811 454.786 77.2744 462.027 81.6254L702.635 226.197C709.876 230.548 713.492 239.067 711.592 247.299L648.448 520.805C646.547 529.037 639.563 535.108 631.147 535.845L351.514 560.309C343.098 561.046 335.165 556.279 331.865 548.503L222.186 290.116Z"
				stroke="url(#paint21_linear_2617_20921)"
			/>
			<path
				opacity="0.232323"
				d="M220.514 286.871C217.349 279.038 219.588 270.058 226.06 264.628L440.639 84.5746C447.11 79.1444 456.342 78.4988 463.507 82.9755L701.056 231.413C708.22 235.89 711.687 244.47 709.644 252.667L641.878 524.46C639.834 532.657 632.745 538.606 624.317 539.195L344.886 558.735C336.459 559.324 328.611 554.42 325.446 546.587L220.514 286.871Z"
				stroke="url(#paint22_linear_2617_20921)"
			/>
			<path
				opacity="0.242424"
				d="M218.913 283.658C215.886 275.771 218.281 266.832 224.846 261.515L442.078 85.604C448.644 80.2876 457.885 79.8032 464.97 84.4042L699.4 236.645C706.485 241.246 709.802 249.886 707.615 258.046L635.269 528.047C633.082 536.207 625.89 542.031 617.454 542.473L338.311 557.102C329.875 557.544 322.113 552.504 319.086 544.617L218.913 283.658Z"
				stroke="url(#paint23_linear_2617_20921)"
			/>
			<path
				opacity="0.252525"
				d="M217.384 280.477C214.495 272.539 217.046 263.643 223.703 258.442L443.509 86.7104C450.166 81.5093 459.415 81.1863 466.418 85.9103L697.668 241.89C704.672 246.614 707.837 255.311 705.509 263.432L628.623 531.564C626.294 539.684 619.002 545.382 610.559 545.677L331.791 555.412C323.348 555.707 315.676 550.531 312.787 542.593L217.384 280.477Z"
				stroke="url(#paint24_linear_2617_20921)"
			/>
			<path
				opacity="0.262626"
				d="M215.924 277.331C213.174 269.344 215.88 260.493 222.627 255.409L444.927 87.8941C451.674 82.81 460.927 82.6485 467.847 87.4941L695.858 247.149C702.778 251.995 705.791 260.745 703.321 268.824L621.939 535.011C619.469 543.09 612.078 548.66 603.632 548.807L325.324 553.665C316.878 553.812 309.297 548.504 306.546 540.516L215.924 277.331Z"
				stroke="url(#paint25_linear_2617_20921)"
			/>
			<path
				opacity="0.272727"
				d="M214.535 274.222C211.925 266.188 214.785 257.386 221.619 252.42L446.333 89.1559C453.168 84.1903 462.423 84.1903 469.257 89.1559L693.971 252.42C700.806 257.386 703.666 266.188 701.055 274.222L615.222 538.39C612.611 546.424 605.124 551.864 596.676 551.864L318.914 551.864C310.466 551.864 302.979 546.424 300.369 538.39L214.535 274.222Z"
				stroke="url(#paint26_linear_2617_20921)"
			/>
			<path
				opacity="0.282828"
				d="M213.214 271.15C210.744 263.071 213.757 254.321 220.677 249.475L447.725 90.4942C454.645 85.6487 463.898 85.8102 470.645 90.8943L692.006 257.702C698.753 262.786 701.459 271.636 698.709 279.624L608.47 541.698C605.719 549.685 598.138 554.993 589.692 554.846L312.559 550.009C304.113 549.861 296.722 544.292 294.252 536.213L213.214 271.15Z"
				stroke="url(#paint27_linear_2617_20921)"
			/>
			<path
				opacity="0.292929"
				d="M211.961 268.116C209.632 259.995 212.798 251.299 219.801 246.575L449.102 91.9096C456.106 87.1856 465.355 87.5085 472.012 92.7096L689.965 262.993C696.622 268.194 699.173 277.091 696.283 285.029L601.685 544.935C598.796 552.874 591.123 558.049 582.681 557.754L306.263 548.101C297.82 547.806 290.527 542.109 288.199 533.988L211.961 268.116Z"
				stroke="url(#paint28_linear_2617_20921)"
			/>
			<path
				opacity="0.30303"
				d="M210.774 265.12C208.588 256.96 211.905 248.32 218.99 243.719L450.462 93.3994C457.547 88.7984 466.789 89.2827 473.354 94.5991L687.845 268.291C694.411 273.607 696.806 282.547 693.778 290.433L594.869 548.101C591.842 555.987 584.08 561.028 575.644 560.586L300.023 546.141C291.587 545.699 284.395 539.875 282.208 531.715L210.774 265.12Z"
				stroke="url(#paint29_linear_2617_20921)"
			/>
			<path
				opacity="0.313131"
				d="M209.654 262.166C207.611 253.969 211.078 245.389 218.242 240.912L451.804 94.9662C458.968 90.4895 468.2 91.1351 474.671 96.5653L685.649 273.596C692.12 279.026 694.359 288.006 691.194 295.839L588.023 551.195C584.859 559.028 577.01 563.932 568.583 563.343L293.843 544.131C285.416 543.542 278.326 537.593 276.282 529.396L209.654 262.166Z"
				stroke="url(#paint30_linear_2617_20921)"
			/>
			<path
				opacity="0.323232"
				d="M208.6 259.252C206.699 251.021 210.315 242.502 217.557 238.151L453.126 96.6064C460.368 92.2554 469.587 93.062 475.963 98.6043L683.374 278.905C689.75 284.447 691.832 293.464 688.531 301.241L581.149 554.217C577.848 561.993 569.915 566.76 561.5 566.023L287.722 542.071C279.306 541.335 272.322 535.263 270.422 527.032L208.6 259.252Z"
				stroke="url(#paint31_linear_2617_20921)"
			/>
			<path
				opacity="0.333333"
				d="M207.61 256.382C205.854 248.119 209.618 239.664 216.934 235.44L454.429 98.3224C461.745 94.0985 470.949 95.0658 477.227 100.719L681.024 284.218C687.302 289.871 689.226 298.923 685.79 306.641L574.248 557.167C570.812 564.885 562.798 569.512 554.396 568.629L281.663 539.964C273.261 539.081 266.384 532.888 264.627 524.625L207.61 256.382Z"
				stroke="url(#paint32_linear_2617_20921)"
			/>
			<path
				opacity="0.343434"
				d="M206.684 253.554C205.072 245.262 208.984 236.874 216.372 232.779L455.71 100.112C463.099 96.016 472.284 97.1438 478.463 102.905L678.596 289.533C684.774 295.294 686.54 304.379 682.97 312.035L567.321 560.044C563.751 567.7 555.657 572.187 547.272 571.158L275.664 537.808C267.279 536.779 260.511 530.467 258.899 522.175L206.684 253.554Z"
				stroke="url(#paint33_linear_2617_20921)"
			/>
			<path
				opacity="0.353535"
				d="M205.821 250.773C204.354 242.453 208.411 234.135 215.87 230.169L456.967 101.975C464.426 98.0092 473.591 99.2972 479.668 105.166L676.091 294.849C682.168 300.717 683.775 309.831 680.071 317.424L560.37 562.849C556.666 570.442 548.495 574.787 540.129 573.611L269.727 535.608C261.361 534.433 254.704 528.004 253.237 519.684L205.821 250.773Z"
				stroke="url(#paint34_linear_2617_20921)"
			/>
			<path
				opacity="0.363636"
				d="M205.018 248.036C203.697 239.692 207.898 231.446 215.425 227.611L458.2 103.911C465.727 100.076 474.868 101.523 480.841 107.497L673.508 300.164C679.482 306.137 680.929 315.278 677.094 322.805L553.394 565.58C549.559 573.107 541.313 577.308 532.969 575.987L263.852 533.363C255.508 532.041 248.964 525.497 247.642 517.153L205.018 248.036Z"
				stroke="url(#paint35_linear_2617_20921)"
			/>
			<path
				opacity="0.373737"
				d="M204.277 245.346C203.102 236.981 207.446 228.809 215.039 225.106L459.407 105.92C467 102.216 476.114 103.823 481.983 109.9L670.85 305.478C676.718 311.554 678.006 320.719 674.04 328.178L546.398 568.238C542.432 575.697 534.114 579.754 525.794 578.287L258.04 531.075C249.721 529.608 243.292 522.95 242.116 514.585L204.277 245.346Z"
				stroke="url(#paint36_linear_2617_20921)"
			/>
			<path
				opacity="0.383838"
				d="M203.597 242.703C202.567 234.318 207.054 226.224 214.711 222.654L460.589 107.999C468.245 104.429 477.33 106.195 483.091 112.373L668.115 310.787C673.876 316.965 675.004 326.151 670.909 333.54L539.382 570.821C535.286 578.21 526.898 582.121 518.606 580.509L252.294 528.743C244.001 527.131 237.689 520.363 236.66 511.978L203.597 242.703Z"
				stroke="url(#paint37_linear_2617_20921)"
			/>
			<path
				opacity="0.393939"
				d="M202.974 240.11C202.091 231.708 206.718 223.694 214.436 220.257L461.741 110.15C469.458 106.714 478.511 108.638 484.163 114.916L665.303 316.092C670.956 322.37 671.923 331.574 667.699 338.89L532.345 573.331C528.121 580.647 519.666 584.411 511.403 582.655L246.61 526.371C238.347 524.615 232.154 517.737 231.271 509.336L202.974 240.11Z"
				stroke="url(#paint38_linear_2617_20921)"
			/>
			<path
				opacity="0.40404"
				d="M202.41 237.565C201.674 229.149 206.44 221.216 214.217 217.915L462.864 112.371C470.641 109.07 479.658 111.152 485.2 117.527L662.416 321.39C667.958 327.766 668.765 336.985 664.414 344.227L525.291 575.765C520.94 583.007 512.421 586.623 504.19 584.722L240.992 523.958C232.761 522.058 226.689 515.074 225.953 506.658L202.41 237.565Z"
				stroke="url(#paint39_linear_2617_20921)"
			/>
			<path
				opacity="0.414141"
				d="M201.903 235.072C201.314 226.644 206.218 218.796 214.051 215.631L463.957 114.662C471.79 111.498 480.77 113.737 486.2 120.208L659.453 326.682C664.883 333.154 665.528 342.386 661.052 349.55L518.221 578.127C513.744 585.292 505.163 588.758 496.967 586.715L235.44 521.509C227.243 519.465 221.294 512.375 220.705 503.948L201.903 235.072Z"
				stroke="url(#paint40_linear_2617_20921)"
			/>
			<path
				opacity="0.424242"
				d="M201.452 232.629C201.01 224.193 206.05 216.431 213.937 213.404L465.019 117.023C472.906 113.995 481.845 116.39 487.162 122.956L656.414 331.965C661.731 338.531 662.215 347.773 657.614 354.858L511.136 580.414C506.535 587.499 497.895 590.816 489.735 588.629L229.954 519.021C221.794 516.835 215.969 509.643 215.527 501.206L201.452 232.629Z"
				stroke="url(#paint41_linear_2617_20921)"
			/>
			<path
				opacity="0.434343"
				d="M201.055 230.239C200.76 221.796 205.935 214.124 213.874 211.234L466.047 119.451C473.986 116.561 482.882 119.112 488.083 125.769L653.3 337.238C658.501 343.895 658.824 353.144 654.1 360.148L504.037 582.626C499.313 589.63 490.616 592.795 482.496 590.467L224.534 516.497C216.413 514.169 210.715 506.876 210.42 498.433L201.055 230.239Z"
				stroke="url(#paint42_linear_2617_20921)"
			/>
			<path
				opacity="0.444444"
				d="M200.712 227.902C200.565 219.456 205.873 211.875 213.861 209.124L467.042 121.947C475.03 119.197 483.88 121.902 488.964 128.649L650.112 342.5C655.196 349.247 655.358 358.5 650.512 365.42L496.926 584.764C492.08 591.684 483.33 594.697 475.251 592.227L219.181 513.939C211.103 511.469 205.533 504.078 205.386 495.631L200.712 227.902Z"
				stroke="url(#paint43_linear_2617_20921)"
			/>
			<path
				opacity="0.454545"
				d="M200.423 225.62C200.423 217.172 205.862 209.685 213.897 207.074L468.002 124.511C476.037 121.9 484.838 124.76 489.804 131.594L646.849 347.749C651.815 354.584 651.815 363.838 646.849 370.673L489.804 586.828C484.838 593.662 476.037 596.522 468.002 593.911L213.897 511.348C205.862 508.737 200.423 501.25 200.423 492.802L200.423 225.62Z"
				stroke="url(#paint44_linear_2617_20921)"
			/>
			<path
				opacity="0.464646"
				d="M200.185 223.392C200.332 214.945 205.902 207.554 213.981 205.084L468.926 127.14C477.005 124.67 485.755 127.683 490.601 134.603L643.513 352.984C648.358 359.904 648.197 369.158 643.113 375.904L482.672 588.816C477.588 595.563 468.738 598.269 460.75 595.518L208.681 508.724C200.693 505.973 195.385 498.392 195.532 489.946L200.185 223.392Z"
				stroke="url(#paint45_linear_2617_20921)"
			/>
			<path
				opacity="0.474747"
				d="M199.996 221.219C200.291 212.776 205.989 205.483 214.109 203.155L469.811 129.834C477.932 127.505 486.628 130.67 491.352 137.674L640.101 358.203C644.825 365.207 644.502 374.456 639.301 381.113L475.531 590.729C470.33 597.386 461.434 599.937 453.496 597.048L203.532 506.068C195.593 503.179 190.418 495.506 190.713 487.063L199.996 221.219Z"
				stroke="url(#paint46_linear_2617_20921)"
			/>
			<path
				opacity="0.484848"
				d="M199.858 219.103C200.3 210.666 206.124 203.474 214.285 201.288L470.659 132.592C478.819 130.406 487.459 133.722 492.06 140.807L636.618 363.406C641.219 370.491 640.734 379.733 635.418 386.298L468.385 592.568C463.068 599.133 454.129 601.528 446.242 598.501L198.452 503.383C190.566 500.356 185.525 492.594 185.967 484.158L199.858 219.103Z"
				stroke="url(#paint47_linear_2617_20921)"
			/>
			<path
				opacity="0.494949"
				d="M199.769 217.043C200.358 208.616 206.307 201.526 214.504 199.483L471.468 135.414C479.665 133.371 488.246 136.837 492.723 144.002L633.062 368.591C637.538 375.755 636.893 384.987 631.463 391.459L461.233 594.331C455.802 600.802 446.823 603.041 438.99 599.877L193.443 500.669C185.61 497.505 180.706 489.656 181.295 481.229L199.769 217.043Z"
				stroke="url(#paint48_linear_2617_20921)"
			/>
			<path
				opacity="0.50505"
				d="M199.727 215.043C200.463 206.627 206.535 199.643 214.766 197.742L472.237 138.3C480.468 136.4 488.987 140.016 493.338 147.257L629.433 373.758C633.784 380.999 632.978 390.219 627.435 396.594L454.076 596.021C448.534 602.397 439.517 604.479 431.74 601.178L188.503 497.93C180.727 494.629 175.96 486.696 176.697 478.28L199.727 215.043Z"
				stroke="url(#paint49_linear_2617_20921)"
			/>
			<path
				opacity="0.515152"
				d="M199.731 213.101C200.614 204.699 206.806 197.821 215.069 196.065L472.963 141.248C481.227 139.492 489.681 143.256 493.905 150.572L625.733 378.904C629.957 386.22 628.989 395.424 623.337 401.702L446.917 597.636C441.264 603.914 432.212 605.838 424.494 602.402L183.633 495.164C175.915 491.728 171.288 483.713 172.171 475.312L199.731 213.101Z"
				stroke="url(#paint50_linear_2617_20921)"
			/>
			<path
				opacity="0.525253"
				d="M199.779 211.217C200.809 202.832 207.12 196.064 215.413 194.452L473.647 144.256C481.94 142.644 490.328 146.555 494.423 153.944L621.961 384.028C626.056 391.417 624.929 400.603 619.167 406.781L439.756 599.176C433.994 605.355 424.909 607.121 417.253 603.55L178.833 492.373C171.176 488.803 166.69 480.709 167.719 472.324L199.779 211.217Z"
				stroke="url(#paint51_linear_2617_20921)"
			/>
			<path
				opacity="0.535354"
				d="M199.871 209.394C201.047 201.029 207.476 194.371 215.795 192.904L474.287 147.325C482.607 145.858 490.925 149.915 494.891 157.374L618.118 389.13C622.084 396.589 620.796 405.754 614.927 411.831L432.594 600.643C426.725 606.72 417.611 608.327 410.018 604.624L174.103 489.56C166.51 485.857 162.165 477.685 163.341 469.32L199.871 209.394Z"
				stroke="url(#paint52_linear_2617_20921)"
			/>
			<path
				opacity="0.545455"
				d="M200.007 207.632C201.329 199.288 207.873 192.744 216.216 191.422L474.884 150.453C483.228 149.132 491.474 153.333 495.309 160.86L614.206 394.208C618.041 401.735 616.593 410.876 610.62 416.849L425.434 602.035C419.46 608.008 410.32 609.456 402.793 605.621L169.445 486.724C161.918 482.889 157.717 474.643 159.038 466.299L200.007 207.632Z"
				stroke="url(#paint53_linear_2617_20921)"
			/>
			<path
				opacity="0.555556"
				d="M200.183 205.93C201.65 197.61 208.307 191.182 216.673 190.006L475.434 153.639C483.8 152.464 491.971 156.809 495.675 164.401L610.223 399.26C613.926 406.853 612.319 415.967 606.242 421.835L418.276 603.353C412.199 609.221 403.034 610.509 395.575 606.543L164.857 483.868C157.398 479.902 153.341 471.584 154.808 463.264L200.183 205.93Z"
				stroke="url(#paint54_linear_2617_20921)"
			/>
			<path
				opacity="0.565657"
				d="M200.4 204.291C202.012 195.998 208.781 189.687 217.165 188.657L475.939 156.884C484.324 155.854 492.418 160.341 495.988 167.997L606.172 404.287C609.742 411.943 607.976 421.028 601.798 426.789L411.122 604.597C404.943 610.359 395.758 611.487 388.369 607.391L160.341 480.993C152.952 476.898 149.041 468.51 150.653 460.217L200.4 204.291Z"
				stroke="url(#paint55_linear_2617_20921)"
			/>
			<path
				opacity="0.575758"
				d="M200.657 202.714C202.413 194.45 209.291 188.258 217.692 187.375L476.396 160.184C484.797 159.301 492.812 163.928 496.248 171.646L602.052 409.285C605.488 417.003 603.564 426.055 597.286 431.708L403.973 605.768C397.695 611.42 388.491 612.388 381.175 608.164L155.897 478.1C148.581 473.876 144.816 465.421 146.573 457.158L200.657 202.714Z"
				stroke="url(#paint56_linear_2617_20921)"
			/>
			<path
				opacity="0.585859"
				d="M200.951 201.2C202.851 192.969 209.836 186.897 218.252 186.161L476.805 163.54C485.221 162.804 493.153 167.571 496.454 175.347L597.865 414.256C601.166 422.032 599.084 431.049 592.708 436.592L396.83 606.866C390.454 612.408 381.235 613.215 373.994 608.864L151.524 475.19C144.283 470.839 140.666 462.32 142.567 454.089L200.951 201.2Z"
				stroke="url(#paint57_linear_2617_20921)"
			/>
			<path
				opacity="0.59596"
				d="M201.282 199.749C203.325 191.552 210.415 185.604 218.842 185.014L477.164 166.951C485.592 166.361 493.44 171.266 496.605 179.098L593.61 419.196C596.775 427.028 594.536 436.008 588.065 441.438L389.695 607.89C383.223 613.32 373.991 613.966 366.827 609.489L147.222 472.265C140.058 467.788 136.591 459.207 138.635 451.01L201.282 199.749Z"
				stroke="url(#paint58_linear_2617_20921)"
			/>
			<path
				opacity="0.606061"
				d="M201.648 198.363C203.835 190.203 211.027 184.379 219.463 183.937L477.475 170.415C485.911 169.973 493.672 175.013 496.7 182.9L589.29 424.105C592.317 431.992 589.922 440.931 583.357 446.248L382.569 608.842C376.004 614.158 366.762 614.643 359.677 610.042L142.994 469.326C135.909 464.725 132.592 456.085 134.778 447.925L201.648 198.363Z"
				stroke="url(#paint59_linear_2617_20921)"
			/>
			<path
				opacity="0.616162"
				d="M202.049 197.041C204.377 188.92 211.67 183.223 220.113 182.928L477.733 173.932C486.176 173.637 493.849 178.812 496.738 186.75L584.903 428.982C587.792 436.92 585.241 445.816 578.584 451.018L375.453 609.721C368.796 614.922 359.547 615.245 352.543 610.521L138.836 466.374C131.832 461.65 128.667 452.953 130.996 444.833L202.049 197.041Z"
				stroke="url(#paint60_linear_2617_20921)"
			/>
			<path
				opacity="0.626263"
				d="M202.483 195.785C204.953 187.706 212.344 182.136 220.79 181.989L477.941 177.5C486.387 177.353 493.968 182.661 496.719 190.649L580.452 433.826C583.202 441.814 580.496 450.664 573.749 455.749L368.349 610.529C361.602 615.613 352.348 615.775 345.428 610.929L134.751 463.411C127.831 458.566 124.818 449.815 127.288 441.737L202.483 195.785Z"
				stroke="url(#paint61_linear_2617_20921)"
			/>
			<path
				opacity="0.636364"
				d="M202.948 194.594C205.559 186.56 213.046 181.12 221.494 181.12L478.096 181.12C486.544 181.12 494.031 186.56 496.642 194.594L575.936 438.637C578.546 446.671 575.687 455.473 568.852 460.439L361.257 611.265C354.422 616.231 345.168 616.231 338.333 611.265L130.738 460.439C123.903 455.473 121.044 446.671 123.654 438.637L202.948 194.594Z"
				stroke="url(#paint62_linear_2617_20921)"
			/>
			<path
				opacity="0.646465"
				d="M203.445 193.468C206.195 185.481 213.776 180.172 222.223 180.32L478.198 184.788C486.645 184.935 494.036 190.505 496.506 198.584L571.357 443.411C573.827 451.49 570.814 460.241 563.894 465.086L354.179 611.93C347.259 616.775 338.006 616.614 331.259 611.53L126.797 457.456C120.05 452.372 117.345 443.522 120.095 435.535L203.445 193.468Z"
				stroke="url(#paint63_linear_2617_20921)"
			/>
			<path
				opacity="0.656566"
				d="M203.97 192.41C206.859 184.471 214.532 179.296 222.974 179.591L478.245 188.505C486.688 188.8 493.981 194.498 496.309 202.618L566.714 448.15C569.043 456.27 565.878 464.967 558.874 469.691L347.116 612.524C340.112 617.248 330.863 616.925 324.206 611.724L122.928 454.467C116.27 449.266 113.72 440.37 116.609 432.432L203.97 192.41Z"
				stroke="url(#paint64_linear_2617_20921)"
			/>
			<path
				opacity="0.666667"
				d="M204.524 191.416C207.551 183.529 215.313 178.489 223.749 178.931L478.239 192.268C486.675 192.71 493.867 198.535 496.054 206.695L562.011 452.85C564.197 461.01 560.881 469.65 553.796 474.251L340.07 613.046C332.985 617.647 323.743 617.163 317.178 611.846L119.131 451.471C112.566 446.155 110.171 437.215 113.198 429.329L204.524 191.416Z"
				stroke="url(#paint65_linear_2617_20921)"
			/>
			<path
				opacity="0.676768"
				d="M205.105 190.491C208.27 182.658 216.118 177.754 224.545 178.343L478.177 196.079C486.604 196.668 493.693 202.617 495.737 210.814L557.246 457.512C559.29 465.709 555.823 474.29 548.659 478.767L333.042 613.499C325.878 617.976 316.645 617.33 310.174 611.9L115.407 448.471C108.935 443.041 106.696 434.061 109.861 426.228L205.105 190.491Z"
				stroke="url(#paint66_linear_2617_20921)"
			/>
			<path
				opacity="0.686869"
				d="M205.711 189.633C209.011 181.856 216.944 177.09 225.36 177.826L478.058 199.934C486.474 200.671 493.458 206.742 495.358 214.974L552.42 462.135C554.321 470.367 550.705 478.886 543.463 483.237L326.032 613.883C318.79 618.234 309.571 617.427 303.195 611.885L111.753 445.467C105.378 439.924 103.296 430.907 106.597 423.131L205.711 189.633Z"
				stroke="url(#paint67_linear_2617_20921)"
			/>
			<path
				opacity="0.69697"
				d="M206.342 188.842C209.778 181.124 217.793 176.497 226.194 177.38L477.883 203.833C486.285 204.717 493.162 210.909 494.919 219.172L547.536 466.717C549.292 474.981 545.528 483.435 538.212 487.659L319.043 614.197C311.726 618.421 302.523 617.453 296.244 611.8L108.173 442.46C101.895 436.807 99.9708 427.755 103.407 420.037L206.342 188.842Z"
				stroke="url(#paint68_linear_2617_20921)"
			/>
			<path
				opacity="0.707071"
				d="M206.995 188.12C210.565 180.464 218.659 175.977 227.044 177.006L477.649 207.777C486.034 208.806 492.803 215.118 494.415 223.411L542.592 471.259C544.203 479.552 540.292 487.939 532.904 492.035L312.073 614.443C304.684 618.539 295.499 617.411 289.32 611.65L104.663 439.454C98.4843 433.692 96.7185 424.608 100.289 416.951L206.995 188.12Z"
				stroke="url(#paint69_linear_2617_20921)"
			/>
			<path
				opacity="0.717172"
				d="M207.67 187.465C211.374 179.872 219.545 175.527 227.911 176.703L477.359 211.761C485.725 212.936 492.382 219.365 493.849 227.685L537.591 475.757C539.058 484.077 535.001 492.395 527.542 496.361L305.127 614.621C297.668 618.587 288.504 617.299 282.427 611.43L101.226 436.446C95.1486 430.578 93.5415 421.464 97.2448 413.871L207.67 187.465Z"
				stroke="url(#paint70_linear_2617_20921)"
			/>
			<path
				opacity="0.727273"
				d="M208.366 186.879C212.202 179.352 220.448 175.151 228.791 176.472L477.009 215.786C485.353 217.108 491.897 223.652 493.219 231.995L532.533 480.213C533.854 488.557 529.653 496.803 522.125 500.638L298.205 614.732C290.678 618.567 281.537 617.119 275.564 611.146L97.8592 433.441C91.8856 427.468 90.4379 418.327 94.2732 410.8L208.366 186.879Z"
				stroke="url(#paint71_linear_2617_20921)"
			/>
			<path
				opacity="0.737374"
				d="M209.082 186.362C213.049 178.903 221.366 174.846 229.686 176.313L476.601 219.851C484.921 221.317 491.349 227.975 492.525 236.34L527.419 484.624C528.595 492.99 524.25 501.161 516.657 504.865L291.308 614.775C283.715 618.478 274.601 616.871 268.733 610.794L94.5649 430.439C88.6965 424.362 87.4086 415.197 91.3746 407.738L209.082 186.362Z"
				stroke="url(#paint72_linear_2617_20921)"
			/>
			<path
				opacity="0.747475"
				d="M209.816 185.914C213.911 178.525 222.299 174.614 230.591 176.226L476.132 223.954C484.425 225.566 490.736 232.335 491.766 240.72L522.25 488.991C523.279 497.376 518.793 505.471 511.136 509.041L284.436 614.753C276.779 618.323 267.695 616.557 261.933 610.379L91.3407 427.441C85.5793 421.262 84.4514 412.077 88.547 404.688L209.816 185.914Z"
				stroke="url(#paint73_linear_2617_20921)"
			/>
			<path
				opacity="0.757576"
				d="M210.566 185.534C214.79 178.218 223.244 174.453 231.507 176.21L475.603 228.094C483.866 229.85 490.058 236.728 490.942 245.129L517.026 493.311C517.909 501.712 513.282 509.727 505.565 513.163L277.591 614.664C269.873 618.1 260.821 616.176 255.168 609.898L88.1875 424.447C82.5347 418.169 81.5674 408.965 85.7913 401.649L210.566 185.534Z"
				stroke="url(#paint74_linear_2617_20921)"
			/>
			<path
				opacity="0.767677"
				d="M211.332 185.223C215.683 177.982 224.201 174.366 232.433 176.266L475.013 232.27C483.244 234.171 489.316 241.155 490.052 249.571L511.75 497.584C512.487 506 507.72 513.933 499.944 517.234L270.774 614.511C262.998 617.811 253.98 615.73 248.438 609.354L85.1052 421.461C79.5629 415.085 78.7563 405.866 83.1073 398.624L211.332 185.223Z"
				stroke="url(#paint75_linear_2617_20921)"
			/>
			<path
				opacity="0.777778"
				d="M212.112 184.983C216.588 177.819 225.169 174.352 233.366 176.396L474.361 236.482C482.558 238.526 488.507 245.616 489.096 254.043L506.422 501.811C507.011 510.238 502.107 518.087 494.274 521.251L263.987 614.293C256.154 617.458 247.174 615.219 241.744 608.748L82.093 418.483C76.6628 412.011 76.0172 402.779 80.4939 395.615L212.112 184.983Z"
				stroke="url(#paint76_linear_2617_20921)"
			/>
			<path
				opacity="0.787879"
				d="M212.904 184.812C217.506 177.727 226.145 174.41 234.306 176.597L473.648 240.728C481.808 242.915 487.632 250.107 488.074 258.543L501.042 505.989C501.484 514.425 496.444 522.187 488.557 525.214L257.23 614.012C249.343 617.04 240.403 614.645 235.087 608.079L79.1507 415.514C73.8343 408.949 73.3499 399.707 77.951 392.622L212.904 184.812Z"
				stroke="url(#paint77_linear_2617_20921)"
			/>
			<path
				opacity="0.79798"
				d="M213.709 184.709C218.433 177.705 227.129 174.54 235.25 176.868L472.871 245.005C480.992 247.334 486.689 254.627 486.984 263.069L495.611 510.116C495.906 518.559 490.731 526.231 482.793 529.121L250.503 613.667C242.565 616.557 233.668 614.006 228.467 607.349L76.2776 412.554C71.0765 405.897 70.7535 396.648 75.4775 389.645L213.709 184.709Z"
				stroke="url(#paint78_linear_2617_20921)"
			/>
			<path
				opacity="0.808081"
				d="M214.524 184.677C219.369 177.756 228.12 174.743 236.198 177.213L472.032 249.315C480.111 251.785 485.681 259.176 485.828 267.623L490.132 514.195C490.279 522.641 484.971 530.222 476.984 532.973L243.81 613.261C235.822 616.011 226.972 613.305 221.888 606.559L73.4744 409.607C68.3903 402.861 68.2288 393.607 73.0743 386.687L214.524 184.677Z"
				stroke="url(#paint79_linear_2617_20921)"
			/>
			<path
				opacity="0.818182"
				d="M215.348 184.713C220.314 177.878 229.115 175.018 237.15 177.629L471.131 253.654C479.165 256.264 484.605 263.752 484.605 272.199L484.605 518.221C484.605 526.669 479.165 534.156 471.131 536.767L237.15 612.792C229.115 615.402 220.314 612.543 215.348 605.708L70.7402 406.672C65.7747 399.838 65.7747 390.583 70.7402 383.749L215.348 184.713Z"
				stroke="url(#paint80_linear_2617_20921)"
			/>
			<path
				opacity="0.828283"
				d="M216.18 184.819C221.264 178.072 230.114 175.367 238.102 178.117L470.165 258.022C478.152 260.773 483.46 268.354 483.313 276.8L479.03 522.197C478.882 530.644 473.313 538.035 465.234 540.505L230.524 612.263C222.445 614.732 213.695 611.719 208.849 604.799L68.0742 403.752C63.2286 396.831 63.3902 387.578 68.4742 380.831L216.18 184.819Z"
				stroke="url(#paint81_linear_2617_20921)"
			/>
			<path
				opacity="0.838384"
				d="M217.02 184.994C222.221 178.337 231.117 175.786 239.055 178.676L469.135 262.418C477.074 265.307 482.249 272.98 481.954 281.423L473.409 526.12C473.114 534.562 467.417 541.855 459.296 544.184L223.935 611.672C215.814 614.001 207.117 610.836 202.393 603.832L65.4771 400.845C60.7531 393.842 61.0761 384.593 66.2772 377.936L217.02 184.994Z"
				stroke="url(#paint82_linear_2617_20921)"
			/>
			<path
				opacity="0.848485"
				d="M217.864 185.24C223.18 178.675 232.119 176.279 240.006 179.307L468.041 266.841C475.928 269.869 480.968 277.63 480.526 286.067L467.743 529.99C467.301 538.427 461.477 545.619 453.316 547.805L217.381 611.024C209.221 613.211 200.581 609.894 195.98 602.809L62.9472 397.957C58.3462 390.872 58.8305 381.63 64.147 375.064L217.864 185.24Z"
				stroke="url(#paint83_linear_2617_20921)"
			/>
			<path
				opacity="0.858586"
				d="M218.712 185.554C224.142 179.082 233.122 176.843 240.955 180.008L466.882 271.289C474.715 274.453 479.619 282.302 479.03 290.729L462.032 533.806C461.443 542.233 455.494 549.323 447.297 551.367L210.865 610.316C202.668 612.36 194.087 608.893 189.61 601.729L60.4844 395.084C56.0077 387.92 56.6532 378.688 62.0835 372.216L218.712 185.554Z"
				stroke="url(#paint84_linear_2617_20921)"
			/>
			<path
				opacity="0.868687"
				d="M219.563 185.938C225.106 179.562 234.123 177.48 241.899 180.781L465.658 275.761C473.435 279.062 478.201 286.995 477.465 295.41L456.279 537.568C455.542 545.984 449.471 552.969 441.24 554.869L204.387 609.551C196.155 611.451 187.637 607.835 183.286 600.594L58.0886 392.231C53.7376 384.99 54.5442 375.77 60.0866 369.395L219.563 185.938Z"
				stroke="url(#paint85_linear_2617_20921)"
			/>
			<path
				opacity="0.878788"
				d="M220.416 186.391C226.069 180.113 235.121 178.189 242.839 181.625L464.369 280.257C472.087 283.693 476.714 291.707 475.831 300.109L450.483 541.276C449.6 549.677 443.408 556.555 435.144 558.311L197.948 608.729C189.685 610.485 181.231 606.721 177.007 599.405L55.759 389.398C51.535 382.082 52.5024 372.878 58.1552 366.6L220.416 186.391Z"
				stroke="url(#paint86_linear_2617_20921)"
			/>
			<path
				opacity="0.888889"
				d="M221.269 186.913C227.03 180.735 236.115 178.969 243.771 182.539L463.014 284.773C470.67 288.344 475.157 296.438 474.127 304.823L444.646 544.927C443.616 553.312 437.305 560.08 429.012 561.692L191.549 607.85C183.257 609.462 174.869 605.551 170.774 598.162L53.4945 386.586C49.3989 379.197 50.5267 370.011 56.2882 363.833L221.269 186.913Z"
				stroke="url(#paint87_linear_2617_20921)"
			/>
			<path
				opacity="0.89899"
				d="M222.121 187.504C227.989 181.427 237.104 179.82 244.696 183.523L461.593 289.311C469.186 293.014 473.531 301.185 472.355 309.551L438.77 548.522C437.594 556.888 431.165 563.545 422.846 565.012L185.192 606.917C176.873 608.384 168.555 604.327 164.589 596.868L51.2961 383.795C47.3301 376.336 48.6181 367.172 54.4865 361.095L222.121 187.504Z"
				stroke="url(#paint88_linear_2617_20921)"
			/>
			<path
				opacity="0.909091"
				d="M222.972 188.165C228.945 182.191 238.086 180.743 245.613 184.579L460.107 293.869C467.634 297.704 471.836 305.95 470.514 314.294L432.855 552.062C431.534 560.405 424.99 566.95 416.646 568.271L178.878 605.93C170.534 607.251 162.288 603.05 158.453 595.523L49.1628 381.029C45.3276 373.502 46.7753 364.361 52.7489 358.388L222.972 188.165Z"
				stroke="url(#paint89_linear_2617_20921)"
			/>
			<path
				opacity="0.919192"
				d="M223.818 188.892C229.895 183.024 239.06 181.736 246.519 185.702L458.554 298.442C466.013 302.409 470.07 310.727 468.603 319.046L426.902 555.542C425.435 563.861 418.778 570.29 410.412 571.466L172.605 604.887C164.24 606.063 156.068 601.718 152.365 594.125L47.0927 378.285C43.3894 370.693 44.9964 361.579 51.0733 355.71L223.818 188.892Z"
				stroke="url(#paint90_linear_2617_20921)"
			/>
			<path
				opacity="0.929293"
				d="M224.661 189.689C230.839 183.927 240.025 182.799 247.414 186.895L456.934 303.034C464.323 307.13 468.234 315.517 466.622 323.81L420.913 558.965C419.301 567.258 412.532 573.569 404.147 574.599L166.377 603.793C157.992 604.823 149.898 600.336 146.327 592.68L45.0867 375.568C41.5164 367.912 43.2823 358.827 49.4607 353.066L224.661 189.689Z"
				stroke="url(#paint91_linear_2617_20921)"
			/>
			<path
				opacity="0.939394"
				d="M225.498 190.554C231.776 184.901 240.98 183.933 248.296 188.157L455.249 307.642C462.565 311.866 466.329 320.32 464.573 328.583L414.888 562.33C413.132 570.593 406.254 576.786 397.853 577.669L160.193 602.648C151.792 603.531 143.777 598.903 140.341 591.186L43.1438 372.877C39.7077 365.16 41.6318 356.107 47.9099 350.455L225.498 190.554Z"
				stroke="url(#paint92_linear_2617_20921)"
			/>
			<path
				opacity="0.949495"
				d="M226.328 191.487C232.704 185.945 241.923 185.138 249.164 189.489L453.496 312.264C460.737 316.615 464.354 325.134 462.453 333.366L408.829 565.637C406.929 573.868 399.944 579.94 391.529 580.676L154.055 601.452C145.639 602.188 137.707 597.422 134.406 589.646L41.263 370.215C37.9621 362.439 40.044 353.421 46.4197 347.879L226.328 191.487Z"
				stroke="url(#paint93_linear_2617_20921)"
			/>
			<path
				opacity="0.959596"
				d="M227.15 192.487C233.621 187.056 242.853 186.411 250.018 190.888L451.677 316.899C458.842 321.375 462.308 329.956 460.265 338.153L402.737 568.882C400.694 577.079 393.604 583.028 385.177 583.617L147.963 600.205C139.536 600.794 131.688 595.89 128.523 588.057L39.4443 367.58C36.2796 359.747 38.5185 350.767 44.99 345.337L227.15 192.487Z"
				stroke="url(#paint94_linear_2617_20921)"
			/>
			<path
				opacity="0.969697"
				d="M227.963 193.555C234.528 188.238 243.77 187.754 250.855 192.355L449.792 321.546C456.877 326.147 460.193 334.787 458.007 342.947L396.614 572.069C394.427 580.229 387.235 586.054 378.799 586.496L141.919 598.91C133.482 599.352 125.721 594.312 122.693 586.425L37.6866 364.975C34.6592 357.088 37.0544 348.149 43.6197 342.832L227.963 193.555Z"
				stroke="url(#paint95_linear_2617_20921)"
			/>
			<path
				opacity="0.979798"
				d="M228.765 194.688C235.422 189.487 244.671 189.164 251.674 193.888L447.839 326.202C454.842 330.926 458.008 339.623 455.679 347.743L390.459 575.195C388.13 583.315 380.837 589.013 372.395 589.308L135.922 597.566C127.479 597.86 119.806 592.685 116.917 584.747L35.9891 362.399C33.0997 354.461 35.6507 345.565 42.3077 340.364L228.765 194.688Z"
				stroke="url(#paint96_linear_2617_20921)"
			/>
			<path
				opacity="0.989899"
				d="M229.555 195.889C236.302 190.805 245.555 190.643 252.475 195.489L445.819 330.87C452.74 335.715 455.753 344.466 453.283 352.544L384.274 578.261C381.804 586.339 374.413 591.909 365.967 592.056L129.973 596.176C121.527 596.323 113.946 591.015 111.195 583.027L34.3516 359.857C31.6012 351.869 34.307 343.019 41.0538 337.935L229.555 195.889Z"
				stroke="url(#paint97_linear_2617_20921)"
			/>
			<path
				d="M230.333 197.156C237.168 192.19 246.422 192.19 253.257 197.156L443.733 335.545C450.568 340.511 453.427 349.312 450.817 357.347L378.061 581.265C375.451 589.3 367.964 594.739 359.516 594.739H124.074C115.626 594.739 108.139 589.3 105.528 581.265L32.773 357.347C30.1625 349.312 33.0223 340.511 39.8568 335.545L230.333 197.156Z"
				stroke="url(#paint98_linear_2617_20921)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2617_20921"
					x1="579.701"
					y1="585.732"
					x2="401.583"
					y2="78.8564"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2617_20921"
					x1="571.902"
					y1="586.962"
					x2="402.966"
					y2="77.9851"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2617_20921"
					x1="564.107"
					y1="588.107"
					x2="404.373"
					y2="77.1951"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_2617_20921"
					x1="556.321"
					y1="589.168"
					x2="405.803"
					y2="76.4868"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_2617_20921"
					x1="548.542"
					y1="590.145"
					x2="407.255"
					y2="75.8598"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_2617_20921"
					x1="540.773"
					y1="591.036"
					x2="408.725"
					y2="75.3135"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_2617_20921"
					x1="533.015"
					y1="591.843"
					x2="410.215"
					y2="74.8489"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_2617_20921"
					x1="525.27"
					y1="592.569"
					x2="411.721"
					y2="74.4676"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_2617_20921"
					x1="517.54"
					y1="593.21"
					x2="413.244"
					y2="74.1676"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_2617_20921"
					x1="509.825"
					y1="593.769"
					x2="414.78"
					y2="73.9492"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint10_linear_2617_20921"
					x1="502.126"
					y1="594.246"
					x2="416.327"
					y2="73.8146"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint11_linear_2617_20921"
					x1="494.447"
					y1="594.641"
					x2="417.887"
					y2="73.7608"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint12_linear_2617_20921"
					x1="486.788"
					y1="594.955"
					x2="419.455"
					y2="73.7908"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint13_linear_2617_20921"
					x1="479.15"
					y1="595.189"
					x2="421.032"
					y2="73.9027"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint14_linear_2617_20921"
					x1="471.536"
					y1="595.341"
					x2="422.615"
					y2="74.0966"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint15_linear_2617_20921"
					x1="463.944"
					y1="595.415"
					x2="424.203"
					y2="74.3733"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint16_linear_2617_20921"
					x1="456.379"
					y1="595.41"
					x2="425.795"
					y2="74.7327"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint17_linear_2617_20921"
					x1="448.841"
					y1="595.326"
					x2="427.389"
					y2="75.1738"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint18_linear_2617_20921"
					x1="441.33"
					y1="595.164"
					x2="428.983"
					y2="75.6973"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint19_linear_2617_20921"
					x1="433.85"
					y1="594.924"
					x2="430.576"
					y2="76.3011"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint20_linear_2617_20921"
					x1="426.4"
					y1="594.61"
					x2="432.167"
					y2="76.989"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint21_linear_2617_20921"
					x1="418.983"
					y1="594.218"
					x2="433.755"
					y2="77.7566"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint22_linear_2617_20921"
					x1="411.599"
					y1="593.752"
					x2="435.337"
					y2="78.6067"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint23_linear_2617_20921"
					x1="404.249"
					y1="593.212"
					x2="436.912"
					y2="79.5379"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint24_linear_2617_20921"
					x1="396.937"
					y1="592.598"
					x2="438.48"
					y2="80.5489"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint25_linear_2617_20921"
					x1="389.66"
					y1="591.911"
					x2="440.037"
					y2="81.6404"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint26_linear_2617_20921"
					x1="382.423"
					y1="591.152"
					x2="441.585"
					y2="82.8128"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint27_linear_2617_20921"
					x1="375.225"
					y1="590.323"
					x2="443.119"
					y2="84.0648"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint28_linear_2617_20921"
					x1="368.068"
					y1="589.423"
					x2="444.64"
					y2="85.3968"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint29_linear_2617_20921"
					x1="360.953"
					y1="588.453"
					x2="446.145"
					y2="86.8064"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint30_linear_2617_20921"
					x1="353.881"
					y1="587.416"
					x2="447.634"
					y2="88.296"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint31_linear_2617_20921"
					x1="346.853"
					y1="586.31"
					x2="449.105"
					y2="89.8623"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint32_linear_2617_20921"
					x1="339.872"
					y1="585.138"
					x2="450.558"
					y2="91.5074"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint33_linear_2617_20921"
					x1="332.936"
					y1="583.9"
					x2="451.99"
					y2="93.2288"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint34_linear_2617_20921"
					x1="326.048"
					y1="582.598"
					x2="453.399"
					y2="95.0281"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint35_linear_2617_20921"
					x1="319.209"
					y1="581.231"
					x2="454.785"
					y2="96.9024"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint36_linear_2617_20921"
					x1="312.419"
					y1="579.803"
					x2="456.147"
					y2="98.8531"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint37_linear_2617_20921"
					x1="305.681"
					y1="578.311"
					x2="457.483"
					y2="100.878"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint38_linear_2617_20921"
					x1="298.993"
					y1="576.76"
					x2="458.792"
					y2="102.977"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint39_linear_2617_20921"
					x1="292.359"
					y1="575.148"
					x2="460.072"
					y2="105.149"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint40_linear_2617_20921"
					x1="285.778"
					y1="573.478"
					x2="461.323"
					y2="107.396"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint41_linear_2617_20921"
					x1="279.252"
					y1="571.751"
					x2="462.543"
					y2="109.715"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint42_linear_2617_20921"
					x1="272.781"
					y1="569.967"
					x2="463.731"
					y2="112.105"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint43_linear_2617_20921"
					x1="266.368"
					y1="568.128"
					x2="464.885"
					y2="114.566"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint44_linear_2617_20921"
					x1="260.011"
					y1="566.235"
					x2="466.005"
					y2="117.098"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint45_linear_2617_20921"
					x1="253.712"
					y1="564.288"
					x2="467.09"
					y2="119.699"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint46_linear_2617_20921"
					x1="247.472"
					y1="562.289"
					x2="468.136"
					y2="122.368"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint47_linear_2617_20921"
					x1="241.292"
					y1="560.24"
					x2="469.146"
					y2="125.105"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint48_linear_2617_20921"
					x1="235.173"
					y1="558.14"
					x2="470.116"
					y2="127.908"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint49_linear_2617_20921"
					x1="229.115"
					y1="555.993"
					x2="471.046"
					y2="130.78"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint50_linear_2617_20921"
					x1="223.119"
					y1="553.799"
					x2="471.935"
					y2="133.716"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint51_linear_2617_20921"
					x1="217.185"
					y1="551.558"
					x2="472.781"
					y2="136.716"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint52_linear_2617_20921"
					x1="211.315"
					y1="549.272"
					x2="473.582"
					y2="139.78"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint53_linear_2617_20921"
					x1="205.51"
					y1="546.943"
					x2="474.341"
					y2="142.906"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint54_linear_2617_20921"
					x1="199.768"
					y1="544.571"
					x2="475.053"
					y2="146.094"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint55_linear_2617_20921"
					x1="194.093"
					y1="542.159"
					x2="475.718"
					y2="149.344"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint56_linear_2617_20921"
					x1="188.483"
					y1="539.706"
					x2="476.336"
					y2="152.652"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint57_linear_2617_20921"
					x1="182.941"
					y1="537.215"
					x2="476.906"
					y2="156.021"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint58_linear_2617_20921"
					x1="177.465"
					y1="534.686"
					x2="477.426"
					y2="159.446"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint59_linear_2617_20921"
					x1="172.057"
					y1="532.122"
					x2="477.896"
					y2="162.929"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint60_linear_2617_20921"
					x1="166.716"
					y1="529.522"
					x2="478.314"
					y2="166.467"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint61_linear_2617_20921"
					x1="161.445"
					y1="526.89"
					x2="478.68"
					y2="170.061"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint62_linear_2617_20921"
					x1="156.243"
					y1="524.226"
					x2="478.993"
					y2="173.709"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint63_linear_2617_20921"
					x1="151.11"
					y1="521.53"
					x2="479.252"
					y2="177.408"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint64_linear_2617_20921"
					x1="146.047"
					y1="518.804"
					x2="479.455"
					y2="181.16"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint65_linear_2617_20921"
					x1="141.054"
					y1="516.05"
					x2="479.604"
					y2="184.961"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint66_linear_2617_20921"
					x1="136.133"
					y1="513.27"
					x2="479.696"
					y2="188.813"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint67_linear_2617_20921"
					x1="131.281"
					y1="510.463"
					x2="479.73"
					y2="192.713"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint68_linear_2617_20921"
					x1="126.502"
					y1="507.632"
					x2="479.708"
					y2="196.659"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint69_linear_2617_20921"
					x1="121.793"
					y1="504.779"
					x2="479.625"
					y2="200.653"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint70_linear_2617_20921"
					x1="117.157"
					y1="501.904"
					x2="479.485"
					y2="204.69"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint71_linear_2617_20921"
					x1="112.593"
					y1="499.008"
					x2="479.284"
					y2="208.772"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint72_linear_2617_20921"
					x1="108.102"
					y1="496.094"
					x2="479.023"
					y2="212.897"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint73_linear_2617_20921"
					x1="103.682"
					y1="493.162"
					x2="478.699"
					y2="217.063"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint74_linear_2617_20921"
					x1="99.3353"
					y1="490.213"
					x2="478.314"
					y2="221.268"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint75_linear_2617_20921"
					x1="95.0615"
					y1="487.25"
					x2="477.867"
					y2="225.513"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint76_linear_2617_20921"
					x1="90.8604"
					y1="484.273"
					x2="477.357"
					y2="229.797"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint77_linear_2617_20921"
					x1="86.7323"
					y1="481.285"
					x2="476.783"
					y2="234.117"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint78_linear_2617_20921"
					x1="82.6766"
					y1="478.284"
					x2="476.144"
					y2="238.471"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint79_linear_2617_20921"
					x1="78.6947"
					y1="475.274"
					x2="475.442"
					y2="242.861"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint80_linear_2617_20921"
					x1="74.786"
					y1="472.255"
					x2="474.675"
					y2="247.282"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint81_linear_2617_20921"
					x1="70.9498"
					y1="469.23"
					x2="473.841"
					y2="251.736"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint82_linear_2617_20921"
					x1="67.1876"
					y1="466.199"
					x2="472.943"
					y2="256.22"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint83_linear_2617_20921"
					x1="63.4977"
					y1="463.164"
					x2="471.977"
					y2="260.734"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint84_linear_2617_20921"
					x1="59.8806"
					y1="460.125"
					x2="470.945"
					y2="265.275"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint85_linear_2617_20921"
					x1="56.3365"
					y1="457.085"
					x2="469.846"
					y2="269.843"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint86_linear_2617_20921"
					x1="52.8649"
					y1="454.045"
					x2="468.679"
					y2="274.437"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint87_linear_2617_20921"
					x1="49.4651"
					y1="451.005"
					x2="467.444"
					y2="279.054"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint88_linear_2617_20921"
					x1="46.1385"
					y1="447.968"
					x2="466.141"
					y2="283.695"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint89_linear_2617_20921"
					x1="42.8843"
					y1="444.935"
					x2="464.771"
					y2="288.358"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint90_linear_2617_20921"
					x1="39.7011"
					y1="441.905"
					x2="463.331"
					y2="293.04"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint91_linear_2617_20921"
					x1="36.59"
					y1="438.882"
					x2="461.823"
					y2="297.742"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint92_linear_2617_20921"
					x1="33.5505"
					y1="435.867"
					x2="460.246"
					y2="302.462"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint93_linear_2617_20921"
					x1="30.5818"
					y1="432.861"
					x2="458.6"
					y2="307.2"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint94_linear_2617_20921"
					x1="27.6843"
					y1="429.863"
					x2="456.885"
					y2="311.951"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint95_linear_2617_20921"
					x1="24.8573"
					y1="426.878"
					x2="455.101"
					y2="316.717"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint96_linear_2617_20921"
					x1="22.1"
					y1="423.903"
					x2="453.247"
					y2="321.494"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint97_linear_2617_20921"
					x1="19.4128"
					y1="420.943"
					x2="451.323"
					y2="326.284"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint98_linear_2617_20921"
					x1="16.7949"
					y1="417.998"
					x2="449.331"
					y2="331.084"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EE2EFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
			</defs>
		</Icon>
	);
};
